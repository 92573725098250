<template>
    <div class="privacy-policy-root-section">
        <PrivacyHeader></PrivacyHeader>
        <PrivacyPolicy></PrivacyPolicy>
        <Footer></Footer>
    </div>
</template>

<script>
    import PrivacyHeader from '../../components/common/PrivacyHeader';
    import Footer from '../../components/common/Footer';
    import PrivacyPolicy from '../../components/privacy_policy/PrivacyPolicy.vue';


    export default {
        name: 'ThankYouComponent',
        components: {
            PrivacyHeader,
            Footer,
            PrivacyPolicy
        }
    }
</script>