<template>
    <div class="count-section" id="service-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-xs-12 col-sm-12 col-md-4">
                    <div class="box-section">
                        <h1>95%</h1>
                        <h5>Customer Satisfaction</h5>
                    </div>
                </div>

                <div class="col-12 col-xs-12 col-sm-12 col-md-4 mt-3 mt-md-0">
                    <div class="box-section">
                        <h1>80%</h1>
                        <h5>Houses We Bought</h5>
                    </div>
                </div>

                <div class="col-12 col-xs-12 col-sm-12 col-md-4 mt-3 mt-md-0">
                    <div class="box-section">
                        <h1>98%</h1>
                        <h5>Cash Offers Made</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default  {
        name: "BuySectionComponent",
        data () {
            return {
            }
        }
    }
</script>

<style scoped lang="scss">
    .count-section {
        background-image: linear-gradient(150deg,rgba(230,138,59,0.9) 25%,rgba(230,138,59,0.52) 70%),url('~@/assets/images/backgrounds/count-section-bg.jpg')!important;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        padding: 70px 15px;
        color: white;
        text-align: center;
        
        .container-fluid {
            .box-section {
                background-color: rgba(230,138,59,0.9);
                border-radius: 10px 10px 10px 10px;
                overflow: hidden;
                padding-top: 20px;
                padding-right: 20px;
                padding-bottom: 20px;
                padding-left: 20px;
                box-shadow: 0px 2px 18px 0px rgb(255 255 255 / 41%);
                h1 {
                    font-size: 50px;
                    font-weight: bold;
                }
            }
        }
    }
</style>