<template>
    <div class="banner-section" id="bannerSection">
        <div class="container-fluid">
            <div class="banner-inner-section">
                <div class="row mt-5">
                    <div v-if="$router.currentRoute._value.path === '/thank-you'" class="col-12 left-column p-2 pl-4 pr-4">
                        <h1 class="banner-title">THANK YOU!</h1>
                        <h5>
                            We appreciate that you have submitted a form. A member of our team will reach out to you shortly. If you can't wait any longer
                            then click below to call us right away!
                        </h5>
                    </div>

                    <div v-if="$router.currentRoute._value.path !== '/thank-you'" class="col-12 col-sm-12 col-md-7 left-column p-2 pl-4 pr-4 mt-5">
                        <h3 class="mt-3">We Are House Buying Experts</h3>
                        <h1 class="banner-title font-weight-bold">Sell Your House <br/> Today With House <br/> Buyers Tennessee</h1>
                        <h5>We give fair cash offers for your home! Give us a call today or fill out one of the forms on our website!</h5>
                    </div>
                    
                    <div v-if="$router.currentRoute._value.path !== '/thank-you'" class="col-12 col-sm-12 col-md-5 right-column mt-5 mt-xs-5 mt-sm-5 mt-md-0 ps-4 pe-4">
                        <div class="form-section p-4">
                            <form @submit.prevent="onSubmit()">
                                <h3 class="text-center">Get A Free Quote!</h3>
                                <p class="text-center">Fill the form to get a free quote for your house!</p>
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name <span class="text-danger font-weight-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="client.name" id="name" name="name" placeholder="Name" required>
                                </div>

                                <div class="mb-3">
                                    <label for="phone-number" class="form-label">Phone Number <span class="text-danger font-weight-bold">*</span></label>
                                    <input type="tel" class="form-control" v-model="client.phone" id="phone" name="phone-number" placeholder="Phone No." title="Please enter your phone number in this pattern: (###) ###-####" pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}" v-mask="'(###) ###-####'" required/>
                                </div>

                                <div class="mb-3">
                                    <label for="property-address" class="form-label">Property Address <span class="text-danger font-weight-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="client.address" id="name" name="property-address" placeholder="Property Address" required>
                                </div>

                                <div class="text-center">
                                    <button class="btn get-offer-btn mt-2" type="submit">Get A Free Quote Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mask} from 'vue-the-mask';
    export default {
        name: 'OfferFormComponent',
        directives: {mask},
        data () {
            return {
                client : {
                    name: '',
                    phone: '',
                    address: ''
                }
            }
        },

        methods: {
            onSubmit() {
                this.$parent.submitGetAnOfferForm(this.client);
            }
        }
    }
</script>

<style scoped lang="scss">
    .banner-section {
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        padding: 100px 15px;
        background-image: linear-gradient(150deg,rgba(230,138,59,0.9) 25%,rgba(230,138,59,0.52) 70%),url('~@/assets/images/backgrounds/banner-bg.jpg')!important;

        .container-fluid {
            .banner-inner-section {
                color: white;
                .left-column {
                    h5 {
                        font-weight: bold;
                        margin-top: 20px;
                        line-height: 1.8rem;
                    }
                    .banner-title {
                        font-size: 60px;
                        font-weight: 500;
                        line-height: 1em;
                    }

                    .call-button {
                        color: white;
                        background-color: #000000;
                        text-align: center;
                        padding: 15px 20px;
                        border-radius: 50px;
                        font-weight: bold;
                    }
                }

                .right-column {
                    .form-section {
                        background-color: rgba(230,138,59,0.9);
                        border-radius: 10px 10px 10px 10px;
                        overflow: hidden;
                        padding-top: 20px;
                        padding-right: 20px;
                        padding-left: 20px;
                        box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 30%);

                        label {
                            font-weight: bold;
                        }

                        .form-control {
                            border-radius: 0px;
                            padding: 8px;
                        }

                        .get-offer-btn {
                            color: #ffffff;
                            background-color: #000000;
                            text-align: center;
                            padding: 15px 30px;
                            border-radius: 90px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
</style>