<template>
    <div class="info-section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-4 p-3">
                    <div class="col-box">
                        <div class="row">
                            <div class="col-3 text-center">
                                <span class="icon"><font-awesome-icon icon="edit" /></span>
                            </div>
                            <div class="col-9">
                                <h4 class="font-base-color">Provide Info</h4>
                                <p>Fill out one of the forms on our website with the necessary info to get the best price for your house!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-4 p-3">
                    <div class="col-box">
                        <div class="row">
                            <div class="col-3 text-center">
                                <span class="icon"><font-awesome-icon icon="home" /></span>
                            </div>
                            <div class="col-9">
                                <h4 class="font-base-color">Get An Offer</h4>
                                <p>Once you fill out one of our forms, we will send you a FAIR offer for your house!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-4 p-3">
                    <div class="col-box">
                        <div class="row">
                            <div class="col-3 text-center">
                                <span class="icon"><font-awesome-icon icon="lock" /></span>
                            </div>
                            <div class="col-9">
                                <h4 class="font-base-color">Closing</h4>
                                <p>If you decided to accept our offer, prepare to move from your house on your schedule</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col text-center mt-3">
                    <h4 class="font-base-color">We Are The Best House Buying Company In Entire Tennessee.</h4>
                    <p>At House Buyers Tennessee, we have countless years of experience buying houses here in Entire Tennessee. 
                        We are committed to getting you the most money for your house when you sell with us! Give us a call today, or fill out one of our forms on our website!</p>
                    <a href="tel:16154425431" class="btn get-offer-btn mt-2">
                        Call Today: 615-442-5431
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default  {
        name: "InfoSectionComponent",
        data () {
            return {
                
            }
        }
    }
</script>

<style scoped lang="scss">
    .info-section {
        padding: 60px 0px;
        .container-fluid {
            .col-box {
                border-radius: 10px 10px 10px 10px;
                padding: 15px;
                box-shadow: 0px 2px 18px 0px #e68a3b;
                min-height: 180px;

                .icon {
                    color: #e68a3b;
                    font-size: 40px;
                }
            }

            .get-offer-btn {
                color: #ffffff;
                background-color: #000000;
                text-align: center;
                padding: 15px 30px;
                border-radius: 90px;
                font-weight: bold;
            }
        }
    }
</style>