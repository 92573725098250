<template>
    <div class="header-section position-absolute" id="headerSection">
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <nav class="navbar navbar-expand-lg">
                        <router-link class="navbar-brand logo" to="/">
                            <img src="@/assets/images/common/logo-white.png" alt="housebuyerstennessee logo" width="90" height="90"/>
                        </router-link>
                        
                        <div>
                            <ul class="navbar-nav ms-auto">
                                <li class="nav-item pt-1">
                                    <a href="mailto:info@tennesseestatehomebuyers.com">info@tennesseestatehomebuyers.com</a>
                                </li>
                                <li class="nav-item cell-phone">
                                    <a href="tel:+16154425431">Call: 615-442-5431</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    window.onscroll = function() {myFunction()};
    function myFunction() {
        let headerSection = document.getElementById("headerSection");
        if(headerSection) {
            if (document.body.scrollTop > 70 || document.documentElement.scrollTop > 70) {
                headerSection.classList.remove("position-absolute");
                headerSection.classList.add("sticky-top");
            } else {
                headerSection.classList.add("position-absolute");
                headerSection.classList.remove("sticky-top");
            }
        }
    }
</script>

<style scoped lang="scss">
    .position-absolute {
        position: absolute;
    }

    .sticky-top {
        background-color: #000000 !important;
        transition: background-color 300ms ease;
        .navbar {
            img {
                width: 50px !important;
                height: 50px !important;
            }
        }
    }

    .header-section {
        padding: 0px 15px;
        width: 100%;
        background: transparent;
        z-index: 1000!important;

        .container-fluid {
            .navbar {
                justify-content: space-between !important;
                .logo {
                    max-height: 54%;
                    vertical-align: middle;
                }
                .navbar-nav {
                    li {
                        padding-left: 20px;
                        a {
                            color: #ffffff;
                            font-weight: bold;
                            font-size: 14px;
                            text-decoration: none;

                            span {
                                color: #ffffff;
                                background-color:#0e4277;
                                border-radius:50px;
                                padding: 10px 15px;
                            }
                        }
                    }

                    .cell-phone {
                        a {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
</style>