<template>
    <div class="footer-section">
        <div class="container-fluid">
            <div class="footer-bottom">
                <div class="row">
                    <div class="col">
                        <p class="text-center">
                            Copyright © House Buyers Tennessee | All Rights Reserved | View Our <a href="/privacy-policy/" target="_blank">Privacy Policy</a> | Designed by <a href="https://goldlevelmarketing.com/" target="_blank">Gold Level Marketing</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
</script>

<style lang="scss" scoped>
    .footer-section {
        background-color: black;
        color: white;
        padding: 25px;
        .container-fluid {
            .footer-bottom {
                p {
                    font-weight: 600;
                    margin-bottom: 0px;
                    a {
                        color: white;
                        text-decoration: none ;
                    }
                }
            }
        }
    }
</style>