<template>
    <div class="thank-you-root-section">
        <Header></Header>
        <div class="thank-you-section">
            <Banner></Banner>
            <Testimonial></Testimonial>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from '../../components/common/Header';
    import Footer from '../../components/common/Footer';
    import Banner from '../../components/home/Banner';
    import Testimonial from '../../components/home/Testimonial';

    export default {
        name: 'ThankYouComponent',
        components: {
            Header,
            Footer,
            Banner,
            Testimonial
        }
    }
</script>