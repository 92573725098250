<template>
    <div class="testimonial-section" id="testimonial-section">
        <div class="container-fluid">
            <div class="row">
                <div class="row text-center">
                    <h2 class="font-base-color font-weight-bold">Why Do Our Clients Love Us?</h2>
                </div>
            </div>

            <div class="row testimonial-lower mt-5">
                <div class="col-12 col-xs-12 col-sm-12 col-md-6 mt-4">
                    <div class="card p-4 card-1">
                        <div class="row">
                            <div class="col col-12 col-sm-3">
                                <div class="text-center">
                                    <img src="@/assets/images/clients/client-1.jpg" width="90" height="90" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-9 mt-3 mt-sm-0">
                                <p class="card-text">“I signed a bad mortgage for my house and as a result, I was unable to afford the payments for my house. House Buyers Tennessee helped me out of this situation by giving me a good cash offer for my house!”</p>
                                <p class="client-name mt-3 font-base-color" >Steve C</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-xs-12 col-sm-12 col-md-6 mt-4">
                    <div class="card p-4 card-1">
                        <div class="row">
                            <div class="col col-12 col-sm-3">
                                <div class="text-center">
                                    <img src="@/assets/images/clients/client-2.jpg" width="90" height="90" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-9 mt-3 mt-sm-0">
                                <p class="card-text">“My husband and I loved our house, but recently the surrounding neighborhood became a bad area. We wanted to move to another location FAST, and House Buyers Tennessee understood my problem and gave us a quick cash offer!”</p>
                                <p class="client-name mt-3 font-base-color" >Emma J</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-xs-12 col-sm-12 col-md-6 mt-4">
                    <div class="card p-4 card-1">
                        <div class="row">
                            <div class="col col-12 col-sm-3">
                                <div class="text-center">
                                    <img src="@/assets/images/clients/client-3.jpg" width="90" height="90" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-9 mt-3 mt-sm-0">
                                <p class="card-text">“I had inherited a house in Gatlinburg, TN from my grandparents, but it was too far for me. House Buyers Tennessee was able to understand my problem and give me a FAIR cash offer for my house!”</p>
                                <p class="client-name mt-3 font-base-color" >Isabella R</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-xs-12 col-sm-12 col-md-6 mt-4">
                    <div class="card p-4 card-1">
                        <div class="row">
                            <div class="col col-12 col-sm-3">
                                <div class="text-center">
                                    <img src="@/assets/images/clients/client-4.jpg" width="90" height="90" />
                                </div>
                            </div>
                            <div class="col col-12 col-sm-9 mt-3 mt-sm-0">
                                <p class="card-text">“A smooth and easy experience made stress-free by House Buyers Tennessee. They were very accommodating. Everyone was professional, courteous, and empathetic with my difficult circumstance.”</p>
                                <p class="client-name mt-3 font-base-color" >Michael G</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
</script>

<style scoped lang="scss">
    .testimonial-section {
        padding: 70px 15px;

        .container-fluid {
            .testimonial-lower {
                .card {
                    border:none !important;
                    border-radius: 10px;
                    background-color: #f5f5f5;
                    img {
                        border-radius: 50%;
                        border: 1px solid #ccc;
                    }
                    .client-name {
                        font-weight: bold;
                    }
                }
            }
        }
    }
</style>