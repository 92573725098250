<template>
    <div class="buy-section" id="service-area">
        <div class="container-fluid">
            <h2>We Buy House in Entire Tennessee.</h2>
            <div class="row mt-4">
                <div class="col-12 col-xs-12 col-sm-12 col-md-6">
                    <div class="box-section">
                        <h3>Are There Any Obligations?</h3>
                        <h5>No Obligations!</h5>
                        <p>At House Buyers Tennessee, we do not force you to accept an offer that we make. 
                            We simply give you a FAIR offer, and YOU choose whether you want to accept it or not! Call us to know more</p>
                    </div>
                </div>
                <div class="col-12 col-xs-12 col-sm-12 col-md-6 mt-3 mt-md-0">
                    <div class="box-section">
                        <h3>How Fast Can You Sell My House?</h3>
                        <h5>As Fast As You Want To!</h5>
                        <p>Here at House Buyers Tennessee, we close on your time. We can close as little as 7 days or you can pick the closing date!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default  {
        name: "BuySectionComponent",
        data () {
            return {
            }
        }
    }
</script>

<style scoped lang="scss">
    .buy-section {
        background-image: linear-gradient(150deg,rgba(230,138,59,0.9) 25%,rgba(230,138,59,0.52) 70%),url('~@/assets/images/backgrounds/buy-section-bg.jpg')!important;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        padding: 70px 15px;
        color: white;
        text-align: center;
        
        .container-fluid {
            .box-section {
                background-color: rgba(230,138,59,0.9);
                border-radius: 10px 10px 10px 10px;
                overflow: hidden;
                padding-top: 20px;
                padding-right: 20px;
                padding-bottom: 20px;
                padding-left: 20px;
                box-shadow: 0px 2px 18px 0px rgb(255 255 255 / 41%);
                min-height: 225px;
            }
        }
    }
</style>