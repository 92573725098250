import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/views/pages/Home.vue';
import ThankYou from '@/views/pages/ThankYou.vue';
import PrivacyPolicy from '@/views/pages/PrivacyPolicy.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},

	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy,
	},

	{
		path: '/thank-you',
		name: 'ThankYou',
		component: ThankYou,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior: function (to) {
		if (to.hash) {
			return {
				el: to.hash
			}
		} else {
			return { top: 0 }
		}
	},
	
});

export default router;
