<template>
    <div class="category-section">
        <div class="container-fluid">
            <h2 class="font-base-color text-center">We Buy All Kinds Of Houses In As-is Condition!</h2>
            <p class="text-center">At House Buyers Tennessee, we never say no to a home that is not in perfect condition. 
                We buy all types of houses to help you close quickly and get the most money for your home!</p>
            <div class="row">
                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/distressed.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">Distressed</h4>
                    </div>
                </div>

                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/retirement.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">Retirement</h4>
                    </div>
                </div>

                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/divorced.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">Divorced</h4>
                    </div>
                </div>

                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/liquidation.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">Liquidation</h4>
                    </div>
                </div>

                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/bankruptcy.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">Bankruptcy</h4>
                    </div>
                </div>

                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/duplex.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">Duplex</h4>
                    </div>
                </div>

                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/judgement.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">Judgements</h4>
                    </div>
                </div>

                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/tax.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">High Taxes</h4>
                    </div>
                </div>

                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/equity.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">No Equity</h4>
                    </div>
                </div>

                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/land.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">Land</h4>
                    </div>
                </div>

                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/flooded-house.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">Flood</h4>
                    </div>
                </div>

                <div class="col-12 col-md-3 p-3 mt-3">
                    <div class="col-box text-center">
                        <div class="icon-section">
                            <img src="@/assets/images/icons/bill.png" width="50" height="50"/>
                        </div>
                        <h4 class="mt-3">Bill</h4>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 text-center text-md-end mt-3">
                    <button class="btn get-offer-btn mt-2">
                        Get a Free Cash Offer Now
                    </button>
                </div>

                <div class="col-12 col-md-6 text-center text-md-start mt-3">
                    <a href="tel:6154425431" class="btn get-offer-btn mt-2">
                        Call Today: 615-442-5431
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default  {
        name: "InfoSectionComponent",
        data () {
            return {
                
            }
        }
    }
</script>

<style scoped lang="scss">
    .category-section {
        padding: 60px 0px;
        .container-fluid {
            .col-box {
                border-radius: 10px 10px 10px 10px;
                overflow: hidden;
                border-width: 1px;
                border: 1px solid #e68a3b;
                padding-top: 20px;
                padding-right: 20px;
                padding-bottom: 20px;
                padding-left: 20px;
            }

            .get-offer-btn {
                color: #ffffff;
                background-color: #000000;
                text-align: center;
                padding: 15px 30px;
                border-radius: 90px;
                font-weight: bold;
            }
        }
    }
</style>