<template>
    <div class="home-root-section">
        <Header></Header>
        <div class="home-section">
            <Banner ref="banner-section"></Banner>
            <InfoSection></InfoSection>
            <CountSection></CountSection>
            <CategorySection></CategorySection>
            <BuySection></BuySection>
            <Testimonial></Testimonial>
            <BannerBottom></BannerBottom>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from '../../components/common/Header';
    import Footer from '../../components/common/Footer';
    import Banner from '../../components/home/Banner';
    import CountSection from '../../components/home/CountSection';
    import BuySection from '../../components/home/BuySection';
    import InfoSection from '../../components/home/InfoSection';
    import CategorySection from '../../components/home/CategorySection';
    import Testimonial from '../../components/home/Testimonial';
    import BannerBottom from '../../components/home/BannerBottom';
    import axios from 'axios';
    import { useGtm } from '@gtm-support/vue-gtm';

    export default {
        name: 'HomeComponent',
        components: {
            Header,
            Footer,
            Banner,
            CountSection,
            BuySection,
            CategorySection,
            InfoSection,
            Testimonial,
            BannerBottom
        },

        methods: {
            async submitGetAnOfferForm(value) {
            console.log('Send Data: '+ value);
             const encodeForm = (data) => {
                    return Object.keys(data)
                        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                        .join('&');
                }

                axios.post(process.env.VUE_APP_API_ENDPOINT_URL, encodeForm(value), {headers: {'Accept': 'application/json'}})
                .then((res) => {
                    this.$router.push({
                        path: '/thank-you'
                    });
                    console.log(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });

                const gtm = useGtm();
                gtm.trackEvent({
                    event: 'FormSubmitted', // Event type [default = 'interaction'] (Optional)
                    category: 'Forms',
                    action: 'form_submission',
                    label: 'Submitted Form',
                    value: 1
                });
            }
        }
    }
</script>